export const couleursTailwind = [
  { nom: "Emerald", classe: "bg-emerald-500", couleur: "#10B981" },
  { nom: "Teal", classe: "bg-teal-500", couleur: "#14B8A6" },
  { nom: "Cyan", classe: "bg-cyan-500", couleur: "#06B6D4" },
  { nom: "Vert", classe: "bg-green-500", couleur: "#10B981" },
  { nom: "Bleu clair", classe: "bg-sky-500", couleur: "#0EA5E9" },
  { nom: "Bleu", classe: "bg-blue-500", couleur: "#3B82F6" },
  { nom: "Indigo", classe: "bg-indigo-500", couleur: "#6366F1" },
  { nom: "Violet", classe: "bg-purple-500", couleur: "#8B5CF6" },
  { nom: "Fuchsia", classe: "bg-fuchsia-500", couleur: "#D946EF" },
  { nom: "Rose", classe: "bg-pink-500", couleur: "#EC4899" },
  { nom: "Rose vif", classe: "bg-rose-500", couleur: "#F43F5E" },
  { nom: "Rouge", classe: "bg-red-500", couleur: "#EF4444" },
  { nom: "Amber", classe: "bg-amber-500", couleur: "#F59E0B" },
  { nom: "Jaune", classe: "bg-yellow-500", couleur: "#F59E0B" },
  { nom: "Orange", classe: "bg-orange-500", couleur: "#F97316" },
  { nom: "Lime", classe: "bg-lime-500", couleur: "#84CC16" },
  { nom: "Gris", classe: "bg-gray-500", couleur: "#6B7280" },
  { nom: "Slate", classe: "bg-slate-500", couleur: "#64748B" },
  { nom: "Stone", classe: "bg-stone-500", couleur: "#78716C" },
  { nom: "Zinc", classe: "bg-zinc-500", couleur: "#71717A" },
];
